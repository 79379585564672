<div class="mb-2">
  <div class="container-fluid summaryHeader">
    <form *ngIf="loanDetails; else noData" [formGroup]="loanSummaryDetailsForm">
      <div class="row mb-2">
        <div class="col-2">
          <div class="form-text">Loan ID</div>
          <label for="" class="col-form-label p-0">
            <dt>{{ loanDetails.loan_Number }}</dt>
          </label>
        </div>
        <div class="col-2 borrower-field">
          <div id="" class="form-text">Borrower Name</div>
          <label *ngIf="!isEditing" for="" class="col-form-label p-0">
            <dt>{{ loanDetails.borrower_Name }}</dt>
          </label>
          <input
            class="form-control"
            *ngIf="isEditing"
            formControlName="borrower_Name"
            [value]="loanSummaryDetailsForm.get('borrower_Name')?.value"
            (change)="onInputChange($event, 'borrower_Name')" />
          <small class="error-text errorTextMsg" *ngIf="showErrorMsg('borrower_Name')">
            Borrower name is required</small
          >
        </div>
        <div class="col-2">
          <div id="" class="form-text">Name of Business</div>
          <label *ngIf="!isEditing" for="" class="col-form-label p-0">
            <dt>{{ loanDetails.business_Name }}</dt>
          </label>
          <input
            class="form-control"
            *ngIf="isEditing"
            formControlName="business_Name"
            [value]="loanSummaryDetailsForm.get('business_Name')?.value"
            (change)="onInputChange($event, 'business_Name')" />
          <small class="error-text errorTextMsg" *ngIf="showErrorMsg('business_Name')">
            Business name is required</small
          >
        </div>
        <div class="col-2">
          <div id="" class="form-text">Income Type</div>
          <label *ngIf="!isEditing" for="" class="col-form-label p-0">
            <dt>{{ loanDetails.business_Type }}</dt>
          </label>
          <select
            class="form-select"
            (change)="onBuisnessTypeChange($event, 'business_Type')"
            formControlName="businessType_Code"
            *ngIf="buisnessTypes.length > 0 && isEditing">
            <option
              *ngFor="let buisnessType of buisnessTypes"
              [value]="buisnessType.code"
              [selected]="buisnessType.code === loanDetails.businessType_Code">
              {{ buisnessType.description }}
            </option>
          </select>
          <small class="error-text errorTextMsg" *ngIf="showErrorMsg('business_Type')">
            Business type is required</small
          >
        </div>
        <div class="col-2">
          <div class="bankStatements">
            <div id="" class="form-text">Bank Statements</div>
            <label *ngIf="!isEditing" for="" class="col-form-label p-0">
              {{ loanDetails.no_Of_Statements }}
            </label>
            <input
              class="form-control text-end"
              *ngIf="isEditing"
              formControlName="no_Of_Statements"
              [value]="loanSummaryDetailsForm.get('no_Of_Statements')?.value"
              (change)="onInputChange($event, 'no_Of_Statements')" />
            <small class="error-text errorTextMsg" *ngIf="showErrorMsg('no_Of_Statements')">
              Bank Statements cannot be zero</small
            >
          </div>
        </div>
        <div class="col-2 mt-3">
          <div class="buttonContainer">
            <button
              type="button"
              class="btn btn-outline-primary btn-sm"
              *ngIf="!isEditing && !isEditable"
              (click)="enableEditingLoanHeader()">
              Edit Loan Details
            </button>
            <button
              type="button"
              class="btn btn-primary btn-sm mx-2"
              *ngIf="isEditing"
              (click)="updateLoanDetailsButton()">
              Save
            </button>
            <button
              type="button"
              class="btn btn-outline-primary btn-sm mx-2"
              *ngIf="isEditing"
              (click)="cancelLoanDetailsButton()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </form>
    <ng-template #noData>
      <div class="container-fluid noData">
        <p>No data available.</p>
      </div>
    </ng-template>
  </div>
</div>
