import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { debounce } from "lodash";
import { PdfViewerComponent } from "ng2-pdf-viewer";
import { CustomStepDefinition, Options as SliderOptions } from "ngx-slider-v2";
import { PageViewport } from "pdfjs-dist";
import { convertBase64ToArrayBuffer } from "src/app/utils/utils";

type PageRenderData = Record<
  string,
  {
    scale: number;
    viewport: PageViewport;
  }
>;

export interface SamrtPDFSource {
  name: string;
  data: PDFData;
}

type PDFData = string;

export interface BoundingBox {
  top: number;
  left: number;
  width: number;
  height: number;
  pageNo: number;
  pageWidth: number;
  zoom: number;
}

@Component({
  selector: "app-smart-pdf-viewer",
  templateUrl: "./smart-pdf-viewer.component.html",
  styleUrl: "./smart-pdf-viewer.component.css",
})
export class SmartPdfViewerComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() pdfSource?: SamrtPDFSource;
  @Input() highlightBoundingBoxs?: BoundingBox[] = [];

  @ViewChild("pdfviewer") pdfviewer!: PdfViewerComponent;

  base64DocumentData: Uint8Array | undefined = undefined;
  intervalId: NodeJS.Timeout | null = null;
  boundingBoxesCache: BoundingBox[] = [];

  pdfIntervalId: NodeJS.Timeout | null = null;

  pageRenderData: PageRenderData = {};

  zoomLevel = 1;
  zoomSlider!: SliderOptions;

  debouncedApplyBoundingBoxes = debounce(this.applyBoundingBoxes.bind(this), 300); // 500ms delay

  constructor() {
    this.initializeZoomSlider();
  }

  ngAfterViewInit(): void {
    this.startInterval();
  }

  private startInterval(): void {
    this.intervalId = setInterval(() => {
      if (this.pdfviewer) {
        const pdfViewNativeElement = this.pdfviewer.pdfViewerContainer.nativeElement;
        const pagesElement = pdfViewNativeElement.getElementsByClassName("pdfViewer")[0];
        if (pagesElement && pagesElement.children && pagesElement.children.length) {
          this.applyBoundingBoxes(); // Apply bounding boxes if they exist
          this.clearInterval(); // Stop the interval once the element is accessed
        }
      }
    }, 1000);
  }

  private clearInterval(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null; // Reset interval ID
    }

    if (this.pdfIntervalId) {
      clearInterval(this.pdfIntervalId);
      this.pdfIntervalId = null;
    }
  }

  ngOnInit(): void {
    this.initialisePDFData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const boundingBoxChange = changes["highlightBoundingBoxs"];
    if (boundingBoxChange && boundingBoxChange.currentValue) {
      this.boundingBoxesCache = boundingBoxChange.currentValue; // Cache the new bounding boxes
      this.zoomLevel = 1;
      if (this.pdfviewer) {
        this.clearExistingBoundingBoxes(); // Clear existing bounding boxes
        this.debouncedApplyBoundingBoxes();
      }
    }

    const currentPdfSourceChange = changes["pdfSource"].currentValue as SamrtPDFSource;
    const previousValuePdfSourceChange = changes["pdfSource"].previousValue as SamrtPDFSource;

    if (
      currentPdfSourceChange &&
      currentPdfSourceChange.data &&
      currentPdfSourceChange !== previousValuePdfSourceChange
    ) {
      this.initialisePDFData();
    }
  }

  ngOnDestroy(): void {
    this.clearInterval();
  }

  initialisePDFData() {
    if (this.pdfSource?.data) {
      this.base64DocumentData = convertBase64ToArrayBuffer(this.pdfSource.data);
    }
  }

  // Clear any existing bounding boxes before applying new ones
  private clearExistingBoundingBoxes(): void {
    const pdfViewNativeElement = this.pdfviewer.pdfViewerContainer.nativeElement;
    const pagesElement = pdfViewNativeElement.getElementsByClassName("pdfViewer")[0];

    if (pagesElement && pagesElement.children) {
      for (let i = 0; i < pagesElement.children.length; i++) {
        const pageItem = pagesElement.children.item(i);
        if (pageItem) {
          const existingBoxes = pageItem.querySelectorAll(".bounding-box");
          existingBoxes.forEach((box) => box.remove());
        }
      }
    }
  }

  private applyBoundingBoxes(): void {
    if (!this.boundingBoxesCache.length) return;

    this.boundingBoxesCache.forEach((box) => {
      this.setBoundingBoxes(box);
    });
  }

  private setBoundingBoxes(inputBox: BoundingBox): void {
    if (!this.pdfviewer) return;

    const pdfViewNativeElement = this.pdfviewer.pdfViewerContainer.nativeElement;
    const pagesElement = pdfViewNativeElement.getElementsByClassName("pdfViewer")[0];
    const pageItem = pagesElement.children.item(inputBox.pageNo - 1);

    const getBoxDataWithViewPort = () => {
      if (!this.pageRenderData[inputBox.pageNo]) {
        console.error("No Viewport found");
        return;
      }

      const viewport = this.pageRenderData[inputBox.pageNo].viewport;
      const scaleFactors = this.calculateScaleFactors(viewport.scale);

      const box = {
        width: (inputBox.width * (viewport.width + 100)) / 100,
        height: (inputBox.height * (viewport.height + 80)) / 100,
        left: (inputBox.left * (viewport.width + scaleFactors.left)) / 100,
        top: (inputBox.top * (viewport.height + scaleFactors.top)) / 100,
        pageNo: inputBox.pageNo,
        pageWidth: viewport.width,
        zoom: inputBox.zoom,
      };

      this.applyBoundingBoxToPage(box);
    };

    if (pageItem) {
      if (this.pageRenderData[inputBox.pageNo] && this.pageRenderData[inputBox.pageNo].viewport) {
        getBoxDataWithViewPort();
      } else {
        pageItem.scrollIntoView({ behavior: "instant", block: "center", inline: "center" });
        new Promise<void>((resolve) => {
          const interval = window.setInterval(() => {
            if (this.pageRenderData[inputBox.pageNo] && this.pageRenderData[inputBox.pageNo].viewport) {
              getBoxDataWithViewPort();
              clearInterval(interval);
              resolve();
            }
          }, 500);
        });
      }
    }
  }

  private applyBoundingBoxToPage(box: BoundingBox): void {
    const pdfViewNativeElement = this.pdfviewer.pdfViewerContainer.nativeElement;
    const pagesElement = pdfViewNativeElement.getElementsByClassName("pdfViewer")[0];
    const pageItem = pagesElement.children.item(box.pageNo - 1);

    if (pageItem) {
      const boundingBoxDiv = document.createElement("div");
      boundingBoxDiv.classList.add("bounding-box");
      boundingBoxDiv.style.position = "absolute";
      boundingBoxDiv.style.top = `${box.top}px`;
      boundingBoxDiv.style.left = `${box.left}px`;
      boundingBoxDiv.style.width = `${box.width}px`;
      boundingBoxDiv.style.height = `${box.height}px`;
      boundingBoxDiv.style.backgroundColor = "#3b82f6";

      boundingBoxDiv.style.pointerEvents = "none";
      boundingBoxDiv.style.opacity = "0.4";
      boundingBoxDiv.style.borderRadius = "5px";

      pageItem?.appendChild(boundingBoxDiv);
      setTimeout(() => this.scrollToBoundingBox(boundingBoxDiv), 100);
    }
  }

  private scrollToBoundingBox(pageItem: HTMLElement): void {
    pageItem.scrollIntoView({ behavior: "smooth", block: "center" });
  }

  private calculateScaleFactors(zoomLevel: number): { left: number; top: number } {
    const defaultZoom = 3 - zoomLevel;
    const zoomStep = zoomLevel >= 2.5 ? 25 : 32;
    const scaleLeft = 90 - defaultZoom * (zoomStep + defaultZoom);
    const scaleTop = 100 - defaultZoom * (zoomStep + defaultZoom);
    return { left: scaleLeft, top: scaleTop };
  }

  pageRendered(event: unknown) {
    const pdfPageData = event as { pageNumber: number; source: { scale: number; viewport: PageViewport } };
    this.pageRenderData[pdfPageData.pageNumber] = {
      scale: pdfPageData.source.scale,
      viewport: pdfPageData.source.viewport,
    };
  }

  updateZoomLevel(): void {
    this.clearExistingBoundingBoxes(); // Clear existing bounding boxes
    this.debouncedApplyBoundingBoxes();
  }

  private initializeZoomSlider(): void {
    const zoomSteps: CustomStepDefinition[] = [];

    for (let i = 10; i <= 300; i += 10) {
      const stepObject: CustomStepDefinition = {
        value: i / 100,
      };

      if (i % 50 === 0) {
        stepObject.legend = i / 100 + "x";
      }

      zoomSteps.push(stepObject);
    }

    this.zoomSlider = {
      showTicks: true,
      stepsArray: zoomSteps,
      floor: 0,
      ceil: 100,
    };
  }

  scrollDocumentToTop() {
    const pdfViewNativeElement = this.pdfviewer.pdfViewerContainer.nativeElement;
    pdfViewNativeElement.scrollTop = 0;
  }
}
