<header>
  <nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
    <div class="container-fluid">
      <a [href]="location" class="navbar-brand d-flex align-items-center p-0">
        <img src="../../../assets/images/MSuite-Logo.png" class="img-fluid" alt="MSuite" />
        <span class="divider"></span>
        <span>{{ pageTitle }}</span>
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <ng-container *ngFor="let actionButton of actionButtons; trackBy: trackByFn">
            <li class="nav-item">
              <button
                class="btn btn-sm"
                [ngClass]="{
                  'btn-primary': actionButton.type === 'primary',
                  'btn-outline-primary': actionButton.type === 'secondary',
                }"
                (click)="handleOnClick(actionButton)"
                [disabled]="actionButton.isDisabled">
                {{ actionButton.name }}
              </button>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </nav>
</header>
